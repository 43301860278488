<template>
  <div id="login-container">
    <!--    <loading :can-cancel="true"></loading>-->
    <!-- login card section -->
    <div class="login-card">
      <!-- form container section -->
      <div class="form-container">
        <!-- form header section -->
        <div class="header">ورود</div>
        <!-- end form header section -->
        <!-- login card image section -->
        <img class="liner" src="../../assets/images/Line-login.svg" alt="" />
        <!-- end login card image section -->
        <!-- form section -->
        <form class="login-form" @submit.prevent>
          <!-- input -->
          <label
            class="input-label"
            :class="[
              this.$store.state.status === 'mobile' ? 'error' : '',
              this.message.mobile ? 'error' : ''
            ]"
          >
            <img src="../../assets/images/mdi_account-circle-outline.svg" />
            <input
              v-model="mobile"
              type="text"
              placeholder="شماره موبایل"
              @input="acceptNumber"
            />
          </label>
          <div
            v-if="this.$store.state.status === 'mobile' || message.mobile"
            class="error-massage"
          >
            {{ this.$store.state.message || this.message.mobile }}
          </div>
          <!-- input -->
          <label
            class="input-label"
            :class="[
              this.$store.state.status === 'mobile' ? 'error' : '',
              this.message.mobile ? 'error' : ''
            ]"
          >
            <img src="../../assets/images/ri_lock-password-fill.svg" />
            <input v-model="password" type="password" placeholder="رمزعبور" />
          </label>
          <div
            v-if="
              this.$store.state.status === 'password' || this.message.password
            "
            class="error-massage"
          >
            {{ this.$store.state.message || this.message.password }}
          </div>
          <div @click="forgetPassword" class="forget-password">
            رمز عبور خود را فراموش کرده ام
          </div>
          <!-- button -->
          <button @click="submit" class="btn-singup">ورود</button>
          <button @click="register" class="btn-register">ثبت نام</button>
          <div class="text-information">
            در صورتی که حساب کاربری ندارید ثبت نام کنید
          </div>
        </form>
        <!-- end form section -->
      </div>
      <!-- end form container section -->
      <img class="login-image" src="../../assets/images/auth-image.svg" />
    </div>
    <!-- end login card section -->
  </div>
</template>
<script>
// import Loading from "vue-loading-overlay";
// import "vue-loading-overlay/dist/vue-loading.css";

export default {
  data() {
    return {
      mobile: "",
      password: "",
      originalMobile: "",
      message: {
        mobile: "",
        password: ""
      },
      visible: false
    };
  },
  // components: {
  //   Loading
  // },
  watch: {
    mobile() {
      this.originalMobile = this.mobile.replace(/\(|\)|\s|-/g, "");
    }
  },
  methods: {
    submit() {
      this.message = {};
      if (this.validateMobile() && this.validatePassword()) {
        let data = {
          mobile: this.originalMobile,
          password: this.password
        };
        // let loader = Loading.show({
        //   loader: "bars"
        // });
        this.$store
          .dispatch("login", data)
          .then(() => {
            // loader.hide();
            if (this.$store.state.status !== "loading") {
              // loader.hide();
            }
            this.$router.push("/dashboard");
          })
          .catch(error => {
            // loader.hide();
            this.$alert(error.response.message, "خطایی رخ داده است", "warning");
            console.log(error);
          });
      }
      if (this.originalMobile.length < 11) {
        this.message.mobile = "فرمت شماره موبایل اشتباه است";
      }

      if (!this.originalMobile) {
        this.message.mobile = "شماره موبایل را وارد کنید";
      }

      if (!this.password) {
        this.message.password = "لطفا رمز عبور را وارد کنید";
      }
    },
    register() {
      this.$router.push("/register");
    },
    forgetPassword() {
      this.$router.push("/forget-password");
    },
    acceptNumber() {
      let x = this.mobile
        .replace(/\D/g, "")
        .match(/(\d{0,4})(\d{0,3})(\d{0,4})/);
      this.mobile = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    validateMobile() {
      return this.originalMobile.length >= 11;
    },
    validatePassword() {
      return this.password.length >= 6;
    },
    onCancel() {
      console.log("User cancelled the loader.");
    }
  }
};
</script>
<style scoped>
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(248, 241, 241);
  opacity: 1;
}

#login-container {
  width: 100%;
  height: 100vh;
  background: linear-gradient(75.38deg, #5c6699 0%, #070d59 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  /*margin-top: 20px;*/
  padding: 0 10px;
}

.login-card {
  background: #1f3c88;
  border-radius: 20px;
  width: 1052px;
  height: 545px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  /*margin: 0px 160px;*/
}

.login-image {
  position: absolute;
  left: -8%;
  top: -18%;
  width: 70%;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 6%;
  width: 88%;
  max-width: 400px;
  min-width: 320px;
  margin-bottom: 175px;
}

.header {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 44px;
  color: #fff;
  margin-bottom: 10px;
  margin-top: 110px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.input-label {
  display: flex;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  width: calc(100% - 50px);
  height: 30px;
  align-items: center;
  margin-top: 21px;
  margin-bottom: 21px;
}

.liner {
}

.input-label input {
  background-color: transparent;
  border: none;
  height: 100%;
  outline: none;
  width: calc(100% - 35px);
  padding-right: 10px;
  color: #ffff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  direction: initial;
  text-align: right;
}

.input-label img {
  height: 20px;
  padding-right: 10px;
}

.input-label-error img {
  height: 24px;
  padding-right: 10px;
}

.input-label-text {
  margin: 0;
  padding: 0;
  position: relative;
  top: -62px;
  right: -93px;
  opacity: 75%;
  color: #fff;
}

.forget-password {
  position: relative;
  margin-top: -15px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  color: #fff;
  opacity: 0.6;
  text-align: right;
  cursor: pointer;
  align-self: flex-end;
  margin-left: 25px;
}

/*
  error section
 */
.error-massage {
  margin-top: -15px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  color: #ff6b6b;
  text-align: right;
  cursor: pointer;
  align-self: flex-start;
  margin-right: 25px;
}

.error {
  border: 1px solid #ff6b6b;
}

.input-label-error {
  border: 1px solid #ff6b6b;
  display: flex;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  width: calc(100% - 50px);
  height: 30px;
  align-items: center;
  margin-top: 21px;
  margin-bottom: 21px;
}

.input-label-error input {
  background-color: transparent;
  border: none;
  height: 100%;
  outline: none;
  width: calc(100% - 35px);
  padding-right: 5px;
  color: #ffff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.input-label-error img {
  height: 24px;
  padding-right: 10px;
}

/* 
  buttton section
 */
.btn-singup {
  font-family: "Vazir Medium FD";
  border: none;
  background: #ffffff;
  border-radius: 10px;
  width: 285px;
  height: 40px;
  margin-top: 32px;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 22px;
  line-height: 34px;
  color: #070d59;
  cursor: pointer;
}

.btn-register {
  font-family: "Vazir Medium FD";
  margin-top: 5px;
  margin-bottom: 5px;
  border: none;
  width: 285px;
  height: 40px;
  background: #1f3c88;
  border: 1px solid #ffffff;
  border-radius: 10px;
  color: #fff;
  font-weight: 500;
  font-size: 22px;
  line-height: 34px;
  cursor: pointer;
}

/*
 text section 
 */
.text-information {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #fff;
  position: relative;
  right: -34px;
}

/* responseiv section */
@media only screen and (max-width: 320px) {
  .form-container {
    min-width: 100%;
    margin: 0 auto;
  }

  .login-card {
    width: 100%;
    /*margin: auto;*/
  }

  .form-container {
    min-width: 100%;
    margin: 0 auto;
  }

  .login-image {
    display: none;
  }

  .header {
    font-size: 1.5em;
  }

  .liner {
    width: 100%;
  }

  .input-label {
    width: 80%;
  }

  .input-label-error {
    width: 80%;
  }

  .input-label input {
    font-size: 0.7em;
  }

  .input-label-error input {
    font-size: 0.7em;
  }

  .input-label img {
    width: 30px;
  }

  .btn-singup {
    width: 70%;
    font-size: 16px;
  }

  .btn-register {
    width: 70%;
    font-size: 16px;
  }

  .text-information {
    margin-right: 60px;
    font-size: 0.5em;
  }

  .error-massage {
    font-size: 0.5em;
  }

  .forget-password {
    font-size: 0.5em;
  }
}

@media (min-width: 321px) and (max-width: 480px) {
  #login-container {
  }

  .form-container {
    margin: 0 auto;
  }

  .login-card {
    width: 100%;
  }

  .text-information {
    margin-right: 60px;
    font-size: 0.5em;
  }

  .header {
    font-size: 1.5em;
  }

  .input-label {
  }

  .input-label input {
    font-size: 0.7em;
  }

  .input-label-error {
  }

  .input-label-error input {
    font-size: 0.7em;
  }

  .input-label img {
    width: 30px;
  }

  .error-massage {
    font-size: 0.5em;
  }

  .forget-password {
    font-size: 0.5em;
  }

  .liner {
    width: 100%;
  }

  .btn-singup {
    width: 80%;
    font-size: 1em;
  }

  .btn-register {
    width: 80%;
    font-size: 1em;
  }
}

@media only screen and (max-width: 1140px) {
  .login-image {
    left: 0;
    top: -13%;
    width: 65%;
  }

  #login-container {
    padding: 0 70px;
  }
}

@media only screen and (max-width: 960px) {
  .login-image {
    left: 0%;
    width: 49%;
    top: 24%;
  }

  #login-container {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 768px) {
  .login-image {
    display: none;
  }

  .login-card {
    margin-top: 70px;
  }
}

/*@media (max-width: 1140px) {*/
/*  #login-container {*/
/*    padding: 0 70px;*/
/*  }*/
/*}*/
</style>
